<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav"
  >
    <component
      :is="resolveNavComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
    <li
      v-if="userData.project_list"
      class="dropdown nav-item redirect-to-projects"
    >
      <a
        href="#"
        target="_self"
        class="nav-link dropdown-toggle d-flex align-items-center"
      ><span class="font-weight-bold text-primary">Quick Links</span>
      </a>
      <ul class="dropdown-menu redirect-list">
        <li
          v-for="(pl, i) in userData.project_list"
          :key="i"
        >
          <a
            class="dropdown-item"
            @click="redirectToProject(pl.porject_code)"
          ><span class="menu-title font-weight-bold text-primary">{{ pl.project_name }}</span>
          </a>
        </li>
      </ul>
    </li>
    <!-- <ul class="nav navbar-nav redirect-to-projects">
      <li
        v-for="(pl, i) in userData.project_list"
        :key="i"
        class="nav-item"
      >
        <b-link
          class="nav-link font-weight-bold text-primary"
          @click="redirectToProject(pl.porject_code)"
        >{{ pl.project_name }}
        </b-link>
      </li>
    </ul> -->

  </ul>
</template>

<script>
import axios from 'axios'
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue'
import constants from '@/constants'
import { failedToast } from '@/utils/common'

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  setup() {
    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }

    return {
      resolveNavComponent,
    }
  },
  methods: {
    redirectToProject(pcode) {
      axios.post(`${constants.COMPANY_API_PREFIX}/project-url`, { project_code: pcode, orange_hrms_code: this.userData.enc_orange_hrms_code }).then(response => {
        if (response.data.data) {
          window.open(response.data.data)
        } else {
          failedToast('Something went wrong!')
        }
      })
    },
  },
}
</script>
<style scoped>
.redirect-to-projects {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
}
.redirect-list {
  min-width: 100px !important
}
.redirect-to-projects:hover .nav-link.dropdown-toggle::after{
  transform: rotate(180deg);
}
.redirect-to-projects:hover .redirect-list {
  display: block;
}
</style>
